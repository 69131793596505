// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-body {
    width: 100%;
    min-height: 1000px;
}

.privacy-container {
    width: 70%;
    color: lightgray;
    margin: 0 auto;
}

.privacy-container p{
    margin-top: 20px;
}

.privacy-container h2{
    margin-top: 20px;
}

.privacy-container h3{
    color: #f36196;
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/pinkker/privacity/Privacity.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".privacy-body {\n    width: 100%;\n    min-height: 1000px;\n}\n\n.privacy-container {\n    width: 70%;\n    color: lightgray;\n    margin: 0 auto;\n}\n\n.privacy-container p{\n    margin-top: 20px;\n}\n\n.privacy-container h2{\n    margin-top: 20px;\n}\n\n.privacy-container h3{\n    color: #f36196;\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
