// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.channel-vod-body {
    width: 95%;
    min-height: 500px;
    padding: 0% 0% 0% 4%;
    display: flex;
    flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/components/channel/vod/Vod.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,oBAAoB;IACpB,aAAa;IACb,eAAe;AACnB","sourcesContent":[".channel-vod-body {\n    width: 95%;\n    min-height: 500px;\n    padding: 0% 0% 0% 4%;\n    display: flex;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
