// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.achievement-body {
    width: 100%;
    min-height: 1500px;
    background-color: #0A0B0D;
}

.achievement-container {
    width: 60%;
    margin: 0 auto;
    height: 500px;
}

.achievement-title {
    color: #ededed;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.achievement-card-container {
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/achievement/Achievement.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".achievement-body {\n    width: 100%;\n    min-height: 1500px;\n    background-color: #0A0B0D;\n}\n\n.achievement-container {\n    width: 60%;\n    margin: 0 auto;\n    height: 500px;\n}\n\n.achievement-title {\n    color: #ededed;\n    font-weight: 600;\n    display: flex;\n    align-items: center;\n}\n\n.achievement-card-container {\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
