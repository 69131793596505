// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.popupfollowers-body {
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0,0,0, 0.5);
    z-index: 9999;
}

.popupfollowers-container {
    width: 23%;
	position: absolute;
	left: 25%;
	right: 25%;
	top: 25%;
	bottom: 25%;
	margin: auto;
	text-align: center;
    background: #0a0a0afc;
    border: 1px solid #ffffff1a;
    border-radius: 5px;
    z-index: 1001;

}

.popupfollowers-close {
    width: 100%;
    height: 0px;
    display: flex;
    justify-content: right;
    position: relative;
    left: 20px;
    top: 0px;
}

.popupfollowers-close button{
    background-color: transparent;
    border: none;
    color: #ededed;
    cursor: pointer;
}


.popupfollowers-content {
    width: 100%;
    margin-top: 10px;
    height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/components/popup/PopupFollowers/PopupFollowers.css"],"names":[],"mappings":";AACA;IACI,eAAe;CAClB,WAAW;CACX,YAAY;CACZ,MAAM;CACN,OAAO;CACP,QAAQ;CACR,SAAS;CACT,YAAY;CACZ,kCAAkC;IAC/B,aAAa;AACjB;;AAEA;IACI,UAAU;CACb,kBAAkB;CAClB,SAAS;CACT,UAAU;CACV,QAAQ;CACR,WAAW;CACX,YAAY;CACZ,kBAAkB;IACf,qBAAqB;IACrB,2BAA2B;IAC3B,kBAAkB;IAClB,aAAa;;AAEjB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;IACV,QAAQ;AACZ;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,cAAc;IACd,eAAe;AACnB;;;AAGA;IACI,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":["\n.popupfollowers-body {\n    position: fixed;\n\twidth: 100%;\n\theight: 100%;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tmargin: auto;\n\tbackground-color: rgba(0,0,0, 0.5);\n    z-index: 9999;\n}\n\n.popupfollowers-container {\n    width: 23%;\n\tposition: absolute;\n\tleft: 25%;\n\tright: 25%;\n\ttop: 25%;\n\tbottom: 25%;\n\tmargin: auto;\n\ttext-align: center;\n    background: #0a0a0afc;\n    border: 1px solid #ffffff1a;\n    border-radius: 5px;\n    z-index: 1001;\n\n}\n\n.popupfollowers-close {\n    width: 100%;\n    height: 0px;\n    display: flex;\n    justify-content: right;\n    position: relative;\n    left: 20px;\n    top: 0px;\n}\n\n.popupfollowers-close button{\n    background-color: transparent;\n    border: none;\n    color: #ededed;\n    cursor: pointer;\n}\n\n\n.popupfollowers-content {\n    width: 100%;\n    margin-top: 10px;\n    height: 400px;\n    overflow-x: hidden;\n    overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
