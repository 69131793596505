// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-body {
    width: 100%;
    min-height: 1500px;
}

.admin-container {
    width: 95%;
    margin: 0 auto;
    position: relative;
    top: 50px;
}

.admin-title {
    color: white;
}

.admin-card-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.admin-card {
    width: 300px;
    border-radius: 10px;
    background-color: #151515;
    height: 200px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin: 5px;
}

.admin-card:hover {
    background-color: #303030;

}

.admin-pinkker-button {
    width: 100px;
    padding: 7px;
    border-radius: 5px;
    border: none;
    margin-right: 5px;
    background-color: #ff60b2;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.admin-pinkker-button:hover {
    background-color: #b62f70;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/Admin.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,cAAc;IACd,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,yBAAyB;;AAE7B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".admin-body {\n    width: 100%;\n    min-height: 1500px;\n}\n\n.admin-container {\n    width: 95%;\n    margin: 0 auto;\n    position: relative;\n    top: 50px;\n}\n\n.admin-title {\n    color: white;\n}\n\n.admin-card-container {\n    margin-top: 20px;\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n.admin-card {\n    width: 300px;\n    border-radius: 10px;\n    background-color: #151515;\n    height: 200px;\n    color: white;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    cursor: pointer;\n    margin: 5px;\n}\n\n.admin-card:hover {\n    background-color: #303030;\n\n}\n\n.admin-pinkker-button {\n    width: 100px;\n    padding: 7px;\n    border-radius: 5px;\n    border: none;\n    margin-right: 5px;\n    background-color: #ff60b2;\n    color: white;\n    font-weight: 600;\n    cursor: pointer;\n}\n\n.admin-pinkker-button:hover {\n    background-color: #b62f70;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
