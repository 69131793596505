// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-answer-input {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.comment-answer-input input{
    border: none;
    padding: 3px;
    width: 60%;
    border-bottom: 1px solid white;
    background-color: transparent;
    color: white;
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/clips/view/card/CommentCard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,UAAU;IACV,8BAA8B;IAC9B,6BAA6B;IAC7B,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".comment-answer-input {\n    margin-top: 10px;\n    display: flex;\n    align-items: center;\n}\n\n.comment-answer-input input{\n    border: none;\n    padding: 3px;\n    width: 60%;\n    border-bottom: 1px solid white;\n    background-color: transparent;\n    color: white;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
