// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-elipsis-recomendado{
    background-color:'inherit' !important;
    padding:5 !important;
    color:white !important;
}

.button-elipsis-recomendado:hover{
    background-color: rgba(0, 0, 0, 0.425);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/home/categories/cardStreamRecomendado.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,oBAAoB;IACpB,sBAAsB;AAC1B;;AAEA;IACI,sCAAsC;IACtC,eAAe;AACnB","sourcesContent":[".button-elipsis-recomendado{\n    background-color:'inherit' !important;\n    padding:5 !important;\n    color:white !important;\n}\n\n.button-elipsis-recomendado:hover{\n    background-color: rgba(0, 0, 0, 0.425);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
