// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usersettings-pagos-body {
    height: 700px;
    width: 95%;
    margin: 0 auto;
}

.usersettings-pagos-container {
    background-color: #151515;
    padding: 30px;
    border-radius: 10px;
    width: 40%;
    height: 600px;
    text-align: justify;
}

.usersettings-pagos-button {
    padding: 7px;
    border: none;
    width: 125px;
    border-radius: 3px;
    background-color: #ff60b2;
    color: white;
    font-weight: 600;
    cursor: pointer;
}


.usersettings-pagos-card-container {
    margin-top: 50px;
}

.usersettings-pagos-card {
    background-color: #101010;
    padding: 30px;
    height: 50px;
    text-align: justify;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.usersettings-pagos-input {
    width: 250px;
    background-color: rgb(56, 56, 56);
    border: none;
    padding: 7px;
    font-size: 12px;
    outline: none;
    color: white;
    border-radius: 5px;
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/settings/user/pagos/Pagos.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;;AAGA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,iCAAiC;IACjC,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".usersettings-pagos-body {\n    height: 700px;\n    width: 95%;\n    margin: 0 auto;\n}\n\n.usersettings-pagos-container {\n    background-color: #151515;\n    padding: 30px;\n    border-radius: 10px;\n    width: 40%;\n    height: 600px;\n    text-align: justify;\n}\n\n.usersettings-pagos-button {\n    padding: 7px;\n    border: none;\n    width: 125px;\n    border-radius: 3px;\n    background-color: #ff60b2;\n    color: white;\n    font-weight: 600;\n    cursor: pointer;\n}\n\n\n.usersettings-pagos-card-container {\n    margin-top: 50px;\n}\n\n.usersettings-pagos-card {\n    background-color: #101010;\n    padding: 30px;\n    height: 50px;\n    text-align: justify;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.usersettings-pagos-input {\n    width: 250px;\n    background-color: rgb(56, 56, 56);\n    border: none;\n    padding: 7px;\n    font-size: 12px;\n    outline: none;\n    color: white;\n    border-radius: 5px;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
