// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addemote-popup-body {
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0,0,0, 0.5);
    z-index: 9999;
   
}

.addemote-popup-container {
    width: 35%;
    height: 500px;
    overflow: scroll;
	margin: auto;
    padding: 30px;
	background: #18181b;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
}


.addmote-popup-content {
    width: 100%;
    height: 450px;
    margin-top: 5px;
    color: white;
}

.addmote-popup-file {
    width: 170px;
    height: 170px;
    opacity: 0;
    position: absolute;
}

.addemote-popup-input {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.addemote-popup-input input {
    padding: 7px;
    background-color: #303030;
    border-radius: 3px;
    margin-left: 5px;
    outline: none;
    font-size: 15px;
    color: white;
    border: none;
}

.addemote-popup-button {
    width: 100px;
    text-align: center;
    color: white;
    font-family: "Montserrat";
    border: none;
    border-radius: 5px;
    background-color: #ff60b2;
    padding: 10px;
    margin-top: 35px;
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/community/popup/AddEmotePopup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;CAClB,WAAW;CACX,YAAY;CACZ,MAAM;CACN,OAAO;CACP,QAAQ;CACR,SAAS;CACT,YAAY;CACZ,kCAAkC;IAC/B,aAAa;;AAEjB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,gBAAgB;CACnB,YAAY;IACT,aAAa;CAChB,mBAAmB;IAChB,iDAAiD;AACrD;;;AAGA;IACI,WAAW;IACX,aAAa;IACb,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,eAAe;IACf,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".addemote-popup-body {\n    position: fixed;\n\twidth: 100%;\n\theight: 100%;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tmargin: auto;\n\tbackground-color: rgba(0,0,0, 0.5);\n    z-index: 9999;\n   \n}\n\n.addemote-popup-container {\n    width: 35%;\n    height: 500px;\n    overflow: scroll;\n\tmargin: auto;\n    padding: 30px;\n\tbackground: #18181b;\n    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);\n}\n\n\n.addmote-popup-content {\n    width: 100%;\n    height: 450px;\n    margin-top: 5px;\n    color: white;\n}\n\n.addmote-popup-file {\n    width: 170px;\n    height: 170px;\n    opacity: 0;\n    position: absolute;\n}\n\n.addemote-popup-input {\n    margin-top: 20px;\n    display: flex;\n    align-items: center;\n}\n\n.addemote-popup-input input {\n    padding: 7px;\n    background-color: #303030;\n    border-radius: 3px;\n    margin-left: 5px;\n    outline: none;\n    font-size: 15px;\n    color: white;\n    border: none;\n}\n\n.addemote-popup-button {\n    width: 100px;\n    text-align: center;\n    color: white;\n    font-family: \"Montserrat\";\n    border: none;\n    border-radius: 5px;\n    background-color: #ff60b2;\n    padding: 10px;\n    margin-top: 35px;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
