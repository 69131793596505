// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emoteinfo-body {
    position: absolute;
    width: 94%;
    min-height: 100px;
    background-color: #18181b;
    border-radius: 5px;
    z-index: 9999999;
    border: 1px solid #ffffff1a;
    box-shadow: 20px 20px 20px 0px #00000057;
}

.emoteinfo-container {
    width: 100%;
    margin: 0 auto;
}

.emoteinfo-close {
    display: flex;
    justify-content: right;
    background-color: red;
}

.emoteinfo-close i{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/channel/chat/dropdown/emote/EmoteDropdown.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,2BAA2B;IAC3B,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".emoteinfo-body {\n    position: absolute;\n    width: 94%;\n    min-height: 100px;\n    background-color: #18181b;\n    border-radius: 5px;\n    z-index: 9999999;\n    border: 1px solid #ffffff1a;\n    box-shadow: 20px 20px 20px 0px #00000057;\n}\n\n.emoteinfo-container {\n    width: 100%;\n    margin: 0 auto;\n}\n\n.emoteinfo-close {\n    display: flex;\n    justify-content: right;\n    background-color: red;\n}\n\n.emoteinfo-close i{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
