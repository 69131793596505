// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: "inter";
    padding-top: 4rem;
}

.summary {
    margin: 10px 0px;
}

.summary-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.navigation-arrow {
    cursor: pointer;
}

.summary-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.summary-details-item {
    display: flex;
    flex-grow: 1;
    height: 100px;
    min-width: 250px;
    margin: 5px 0;
    background: #18181b;
    /* margin: 10px; */
    flex-grow: 1;
    padding: 18px;
    flex-direction: column;
    justify-content: space-between;
}

.property-name {
    display: flex;
    font-weight: bold;
    flex-direction: column;

}

.comparison {
    color: #ff69b433;
    margin-right: 10px;
    /* Color rosado */
    font-family: inter;
}

.comparisonLast {
    color: #ff69b433;
    font-family: inter;
}

.property-value {
    color: #ff69b4;
    font-weight: 700;
    font-family: inter;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/analytics/StreamSummaryAnalytics.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,SAAS;AACb;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,sBAAsB;;AAE1B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".summary-container {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    font-family: \"inter\";\n    padding-top: 4rem;\n}\n\n.summary {\n    margin: 10px 0px;\n}\n\n.summary-header {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    justify-content: space-between;\n}\n\n.navigation-arrow {\n    cursor: pointer;\n}\n\n.summary-details {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    gap: 10px;\n}\n\n.summary-details-item {\n    display: flex;\n    flex-grow: 1;\n    height: 100px;\n    min-width: 250px;\n    margin: 5px 0;\n    background: #18181b;\n    /* margin: 10px; */\n    flex-grow: 1;\n    padding: 18px;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.property-name {\n    display: flex;\n    font-weight: bold;\n    flex-direction: column;\n\n}\n\n.comparison {\n    color: #ff69b433;\n    margin-right: 10px;\n    /* Color rosado */\n    font-family: inter;\n}\n\n.comparisonLast {\n    color: #ff69b433;\n    font-family: inter;\n}\n\n.property-value {\n    color: #ff69b4;\n    font-weight: 700;\n    font-family: inter;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
