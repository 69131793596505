// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dropdownbalance-menu {
    position: absolute;
    top: 52px;
    right: 70px;
    display: flex;
    background: #0a0a0afc;
    box-shadow: 0px 5px 20px 5px black;
    border-radius: 5px;
    font-family: 'Roboto';
    width: 190px;
}
    
.dropdownbalance-menu li {
      display: flex;
      margin: 5px;
      width: 180px;
      font-size: 12px;
}
    
    
.dropdownbalance-menu.clicked {
    display: none;
}
    
.dropdownbalance-button {
      background-color: #f36196;
      border: none;
      width: 100%;
      font-family: 'Poppins';
      font-weight: 600;
      padding: 5px;
      color: black;
      cursor: pointer;
      border-radius: 3px;

}

.dropdownbalance-news-card {
    width: 100%;
    min-height: 200px;
}
    
@media screen and (max-width: 960px) {
  
      .dropdownbalance-menu {
        width: 220%;
        top: 60px;
        z-index: 200;
  
      }
  
      .fa-caret-down {
        display: none;
      }
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/balance/DropdownBalance.css"],"names":[],"mappings":";AAGA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,aAAa;IACb,qBAAqB;IACrB,kCAAkC;IAClC,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;MACM,aAAa;MACb,WAAW;MACX,YAAY;MACZ,eAAe;AACrB;;;AAGA;IACI,aAAa;AACjB;;AAEA;MACM,yBAAyB;MACzB,YAAY;MACZ,WAAW;MACX,sBAAsB;MACtB,gBAAgB;MAChB,YAAY;MACZ,YAAY;MACZ,eAAe;MACf,kBAAkB;;AAExB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;;MAEM;QACE,WAAW;QACX,SAAS;QACT,YAAY;;MAEd;;MAEA;QACE,aAAa;MACf;AACN","sourcesContent":["\n@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');\n\n.dropdownbalance-menu {\n    position: absolute;\n    top: 52px;\n    right: 70px;\n    display: flex;\n    background: #0a0a0afc;\n    box-shadow: 0px 5px 20px 5px black;\n    border-radius: 5px;\n    font-family: 'Roboto';\n    width: 190px;\n}\n    \n.dropdownbalance-menu li {\n      display: flex;\n      margin: 5px;\n      width: 180px;\n      font-size: 12px;\n}\n    \n    \n.dropdownbalance-menu.clicked {\n    display: none;\n}\n    \n.dropdownbalance-button {\n      background-color: #f36196;\n      border: none;\n      width: 100%;\n      font-family: 'Poppins';\n      font-weight: 600;\n      padding: 5px;\n      color: black;\n      cursor: pointer;\n      border-radius: 3px;\n\n}\n\n.dropdownbalance-news-card {\n    width: 100%;\n    min-height: 200px;\n}\n    \n@media screen and (max-width: 960px) {\n  \n      .dropdownbalance-menu {\n        width: 220%;\n        top: 60px;\n        z-index: 200;\n  \n      }\n  \n      .fa-caret-down {\n        display: none;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
