// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.streammanager-atajos-card {
    width: 43%;
    min-width: 150px;
    padding: 10px;
    height: 100px;
    margin-left: 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.streammanager-atajos-card:hover {
    filter: brightness(95%);
}

@media screen and (max-width: 768px) {
    .streammanager-atajos-card {
        width: 95%;
        margin: 0 auto;
        height: 30px;
        display: flex;
        align-items: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/stream-manager/card/Card.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI;QACI,UAAU;QACV,cAAc;QACd,YAAY;QACZ,aAAa;QACb,mBAAmB;IACvB;AACJ","sourcesContent":[".streammanager-atajos-card {\n    width: 43%;\n    min-width: 150px;\n    padding: 10px;\n    height: 100px;\n    margin-left: 5px;\n    border-radius: 5px;\n    cursor: pointer;\n    margin-top: 10px;\n}\n\n.streammanager-atajos-card:hover {\n    filter: brightness(95%);\n}\n\n@media screen and (max-width: 768px) {\n    .streammanager-atajos-card {\n        width: 95%;\n        margin: 0 auto;\n        height: 30px;\n        display: flex;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
