// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settingstream-body {
    width: 100%;
    min-height: 1000px;
    background-color: #0a0b0d;
}


.settingstream-container {
    width: 95%;
    position: relative;
    top: 75px;
    color: #ededed;
    font-family: 'Montserrat', sans-serif;
    margin: 0 auto;
    min-height: 500px;
}


.settingstream-content {
    width: 100%;
    border-radius: 5px;
}


.settingstream-camp {
    width: 100%;
    margin: 0 auto;
    height: 150px;
    padding: 10px;
    font-size: 14px;
}

.settingstream-input {
    padding: 7px;
    background-color: rgb(53, 53, 53);
    border: none;
    margin-top: 10px;
    outline: none;
    border-radius: 5px;
    color: #ededed;
}

.button-copy {
    padding: 5px;

    border-radius: 5px;
    border: none;
    outline: none;
    color: #ededed;
    cursor: pointer;
    margin: 5px;
    background-color: transparent;
}

.button-copy:hover {
    background-color: #303030;

}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/settings/stream/SettingsStream.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,yBAAyB;AAC7B;;;AAGA;IACI,UAAU;IACV,kBAAkB;IAClB,SAAS;IACT,cAAc;IACd,qCAAqC;IACrC,cAAc;IACd,iBAAiB;AACrB;;;AAGA;IACI,WAAW;IACX,kBAAkB;AACtB;;;AAGA;IACI,WAAW;IACX,cAAc;IACd,aAAa;IACb,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,iCAAiC;IACjC,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,YAAY;;IAEZ,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,cAAc;IACd,eAAe;IACf,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;;AAE7B","sourcesContent":[".settingstream-body {\n    width: 100%;\n    min-height: 1000px;\n    background-color: #0a0b0d;\n}\n\n\n.settingstream-container {\n    width: 95%;\n    position: relative;\n    top: 75px;\n    color: #ededed;\n    font-family: 'Montserrat', sans-serif;\n    margin: 0 auto;\n    min-height: 500px;\n}\n\n\n.settingstream-content {\n    width: 100%;\n    border-radius: 5px;\n}\n\n\n.settingstream-camp {\n    width: 100%;\n    margin: 0 auto;\n    height: 150px;\n    padding: 10px;\n    font-size: 14px;\n}\n\n.settingstream-input {\n    padding: 7px;\n    background-color: rgb(53, 53, 53);\n    border: none;\n    margin-top: 10px;\n    outline: none;\n    border-radius: 5px;\n    color: #ededed;\n}\n\n.button-copy {\n    padding: 5px;\n\n    border-radius: 5px;\n    border: none;\n    outline: none;\n    color: #ededed;\n    cursor: pointer;\n    margin: 5px;\n    background-color: transparent;\n}\n\n.button-copy:hover {\n    background-color: #303030;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
