// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdownretweet-body {
    z-index: 100000000000000;
}

.dropdownretweet-container {
    /* position: absolute; */
    z-index: 100000000000000;
    background-color: #151515;
    height: 70px;
    padding: 20px;
    width: 120px;
    left: 15px;
    border-radius: 20px;
    margin-top: 19px;
    box-shadow: 1px 5px 23px 0px rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: 1px 5px 23px 0px rgba(255, 255, 255, 0.3);
    -moz-box-shadow: 1px 5px 23px 0px rgba(255, 255, 255, 0.3);
}

.dropdownretweet-card {
    padding: 5px;
    display: flex;
    align-items: center;
}

.dropdownretweet-card>i {
    padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/muro/tweet/DropdownReTweet.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;IACxB,wBAAwB;IACxB,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,UAAU;IACV,mBAAmB;IACnB,gBAAgB;IAChB,qDAAqD;IACrD,6DAA6D;IAC7D,0DAA0D;AAC9D;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".dropdownretweet-body {\n    z-index: 100000000000000;\n}\n\n.dropdownretweet-container {\n    /* position: absolute; */\n    z-index: 100000000000000;\n    background-color: #151515;\n    height: 70px;\n    padding: 20px;\n    width: 120px;\n    left: 15px;\n    border-radius: 20px;\n    margin-top: 19px;\n    box-shadow: 1px 5px 23px 0px rgba(255, 255, 255, 0.3);\n    -webkit-box-shadow: 1px 5px 23px 0px rgba(255, 255, 255, 0.3);\n    -moz-box-shadow: 1px 5px 23px 0px rgba(255, 255, 255, 0.3);\n}\n\n.dropdownretweet-card {\n    padding: 5px;\n    display: flex;\n    align-items: center;\n}\n\n.dropdownretweet-card>i {\n    padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
