// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featured-body {
    width: 100%;
    display: flex;
    align-items: flex-start;

}


.featured-video video{
    width: 100%;
    height: 300px;
    position: relative;
    top: -15px;
}

.featured-info {
    width: 35%;
    margin-left: 10px;
    color: #ededed;
    margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/channel/featured/Featured.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;;AAE3B;;;AAGA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB","sourcesContent":[".featured-body {\n    width: 100%;\n    display: flex;\n    align-items: flex-start;\n\n}\n\n\n.featured-video video{\n    width: 100%;\n    height: 300px;\n    position: relative;\n    top: -15px;\n}\n\n.featured-info {\n    width: 35%;\n    margin-left: 10px;\n    color: #ededed;\n    margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
