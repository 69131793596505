// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.channel-muro-body {
    /* background-color: transparent;
    width: 100%;
    min-height: 2500px;
    position: absolute;
    top: 65px; */
}

.channel-muro-container {
    width: 95%;
    min-height: 500px;
    margin: 0 auto;
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/channel/muro/Muro.css"],"names":[],"mappings":"AAAA;IACI;;;;gBAIY;AAChB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,cAAc;IACd,aAAa;AACjB","sourcesContent":[".channel-muro-body {\n    /* background-color: transparent;\n    width: 100%;\n    min-height: 2500px;\n    position: absolute;\n    top: 65px; */\n}\n\n.channel-muro-container {\n    width: 95%;\n    min-height: 500px;\n    margin: 0 auto;\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
