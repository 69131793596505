// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-popup-body {
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0,0,0, 0.5);
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-popup-container {
    width: 30%;
    height: 500px;
	margin: auto;
	background: #18181b;
    border-radius: 5px;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
}

.admin-popup-close {
    display: flex;
    align-items: center;
    justify-content: right;
}


.admin-popup-button-close {
    background-color: #18181b;
    border: none;
    cursor: pointer;
}


.admin-popup-confirm-button {
    border: none;
    padding: 5px;
    width: 125px;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    color: white;
    background-color: #f36196;
    margin-top: 50px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/popup/AdminConfirm.css"],"names":[],"mappings":"AAAA;IACI,eAAe;CAClB,WAAW;CACX,YAAY;CACZ,MAAM;CACN,OAAO;CACP,QAAQ;CACR,SAAS;CACT,YAAY;CACZ,kCAAkC;IAC/B,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,aAAa;CAChB,YAAY;CACZ,mBAAmB;IAChB,kBAAkB;IAClB,iDAAiD;AACrD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;;;AAGA;IACI,yBAAyB;IACzB,YAAY;IACZ,eAAe;AACnB;;;AAGA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".admin-popup-body {\n    position: fixed;\n\twidth: 100%;\n\theight: 100%;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tmargin: auto;\n\tbackground-color: rgba(0,0,0, 0.5);\n    z-index: 2000;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.admin-popup-container {\n    width: 30%;\n    height: 500px;\n\tmargin: auto;\n\tbackground: #18181b;\n    border-radius: 5px;\n    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);\n}\n\n.admin-popup-close {\n    display: flex;\n    align-items: center;\n    justify-content: right;\n}\n\n\n.admin-popup-button-close {\n    background-color: #18181b;\n    border: none;\n    cursor: pointer;\n}\n\n\n.admin-popup-confirm-button {\n    border: none;\n    padding: 5px;\n    width: 125px;\n    font-weight: 700;\n    font-size: 18px;\n    border-radius: 5px;\n    color: white;\n    background-color: #f36196;\n    margin-top: 50px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
