// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
    width: 88.6%;
    padding-left: 10%;
    background-color: #080808;
    position: relative;
    transition: 0.15s;
}

.layout-expanded {
    width: 98%;
    padding-left: 3%;
    background-color: #080808;
    position: relative;
    transition: 0.15s;

}


@media screen and (max-width: 1000px) {}`, "",{"version":3,"sources":["webpack://./src/components/layout/Layout.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;;AAErB;;;AAGA,uCAAuC","sourcesContent":[".layout {\n    width: 88.6%;\n    padding-left: 10%;\n    background-color: #080808;\n    position: relative;\n    transition: 0.15s;\n}\n\n.layout-expanded {\n    width: 98%;\n    padding-left: 3%;\n    background-color: #080808;\n    position: relative;\n    transition: 0.15s;\n\n}\n\n\n@media screen and (max-width: 1000px) {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
