// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.achievementreward-popup-body {
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0,0,0, 0.5);
    z-index: 9999;
   
}

.achievementreward-popup-container {
    width: 35%;
    height: 500px;
	margin: auto;
    padding: 30px;
	background: #18181b;
	border-radius: 5px;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
}
`, "",{"version":3,"sources":["webpack://./src/components/achievement/popup/AchievementRewardPopup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;CAClB,WAAW;CACX,YAAY;CACZ,MAAM;CACN,OAAO;CACP,QAAQ;CACR,SAAS;CACT,YAAY;CACZ,kCAAkC;IAC/B,aAAa;;AAEjB;;AAEA;IACI,UAAU;IACV,aAAa;CAChB,YAAY;IACT,aAAa;CAChB,mBAAmB;CACnB,kBAAkB;IACf,iDAAiD;AACrD","sourcesContent":[".achievementreward-popup-body {\n    position: fixed;\n\twidth: 100%;\n\theight: 100%;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tmargin: auto;\n\tbackground-color: rgba(0,0,0, 0.5);\n    z-index: 9999;\n   \n}\n\n.achievementreward-popup-container {\n    width: 35%;\n    height: 500px;\n\tmargin: auto;\n    padding: 30px;\n\tbackground: #18181b;\n\tborder-radius: 5px;\n    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
