// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.embleminfo-body {
    position: absolute;
    width: 250px;
    min-height: 190px;
    background-color: #18181b;
    border-radius: 5px;
    z-index: 9999999;
    border: 1px solid #ffffff1a;
}

.embleminfo-container {
    width: 90%;
    margin: 0 auto;
}

.embleminfo-close {
    display: flex;
    justify-content: right;
    margin-top: 10px;
}

.embleminfo-close i{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/emblem/EmblemDropdown.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".embleminfo-body {\n    position: absolute;\n    width: 250px;\n    min-height: 190px;\n    background-color: #18181b;\n    border-radius: 5px;\n    z-index: 9999999;\n    border: 1px solid #ffffff1a;\n}\n\n.embleminfo-container {\n    width: 90%;\n    margin: 0 auto;\n}\n\n.embleminfo-close {\n    display: flex;\n    justify-content: right;\n    margin-top: 10px;\n}\n\n.embleminfo-close i{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
