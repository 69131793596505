// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.donationcard-body-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.donationcard-body {
    width: 100%;
    height: 90px;
    position: absolute;
    top: 110px;
    border-radius: 5px;
    cursor: move;
    z-index: 99999;
    padding-bottom: 10px;
    border: 1px solid #ffffff1a;

}


.donationcard-close {
    width: 95%;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: right;
}



.donationcard-container {
    width: 80%;
    position: relative;
    top: -10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.donationcard-primary {
    display: flex;
    align-items: center;
    height: 75px;
}


.donationcard-button {
    border: none;
    background-color: #f36196;
    color: #ededed;
    font-family: "Poppins";
    padding: 5px;
    width: 100%;
    border-end-start-radius: 3px;
    border-end-end-radius: 3px;
    cursor: pointer;
    margin-top: 15px;
}

.donationcard-button:hover {
    background-color: #bd3b7e;
}`, "",{"version":3,"sources":["webpack://./src/components/channel/chat/donation/card/DonationCard.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,oBAAoB;IACpB,2BAA2B;;AAE/B;;;AAGA;IACI,UAAU;IACV,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;;;;AAIA;IACI,UAAU;IACV,kBAAkB;IAClB,UAAU;IACV,cAAc;IACd,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,sBAAsB;IACtB,YAAY;IACZ,WAAW;IACX,4BAA4B;IAC5B,0BAA0B;IAC1B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".donationcard-body-container {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 1000;\n}\n\n.donationcard-body {\n    width: 100%;\n    height: 90px;\n    position: absolute;\n    top: 110px;\n    border-radius: 5px;\n    cursor: move;\n    z-index: 99999;\n    padding-bottom: 10px;\n    border: 1px solid #ffffff1a;\n\n}\n\n\n.donationcard-close {\n    width: 95%;\n    padding-top: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: right;\n}\n\n\n\n.donationcard-container {\n    width: 80%;\n    position: relative;\n    top: -10px;\n    margin: 0 auto;\n    display: flex;\n    align-items: center;\n}\n\n.donationcard-primary {\n    display: flex;\n    align-items: center;\n    height: 75px;\n}\n\n\n.donationcard-button {\n    border: none;\n    background-color: #f36196;\n    color: #ededed;\n    font-family: \"Poppins\";\n    padding: 5px;\n    width: 100%;\n    border-end-start-radius: 3px;\n    border-end-end-radius: 3px;\n    cursor: pointer;\n    margin-top: 15px;\n}\n\n.donationcard-button:hover {\n    background-color: #bd3b7e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
