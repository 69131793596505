// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userbanner-body {
    width: 50%;
    margin-top: 50px;
}

.userbanner-container {
    width:  100%;
    margin: 0 auto;
    background-color: rgb(22, 22, 22);
    border-radius: 5px;
    min-height: 150px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
}

.custom-file-input {
    color: transparent;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
      width: 30%;
      text-align: center;
    content: 'Actualizar';
    color: white;
    display: inline-block;
    background-color: rgb(37, 37, 37);
    border-radius: 5px;
    padding: 10px;
    outline: none;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 12px;
    margin-left: 80px;
    margin-top: 10px;
  }
  .custom-file-input:hover::before {
    background-color: rgb(54, 54, 54);
  }`, "",{"version":3,"sources":["webpack://./src/components/settings/user/banner/Banner.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,iCAAiC;IACjC,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;EACA;MACI,UAAU;MACV,kBAAkB;IACpB,qBAAqB;IACrB,YAAY;IACZ,qBAAqB;IACrB,iCAAiC;IACjC,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,eAAe;IACf,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;EAClB;EACA;IACE,iCAAiC;EACnC","sourcesContent":[".userbanner-body {\n    width: 50%;\n    margin-top: 50px;\n}\n\n.userbanner-container {\n    width:  100%;\n    margin: 0 auto;\n    background-color: rgb(22, 22, 22);\n    border-radius: 5px;\n    min-height: 150px;\n    padding-bottom: 20px;\n    padding-top: 20px;\n    text-align: center;\n}\n\n.custom-file-input {\n    color: transparent;\n  }\n  .custom-file-input::-webkit-file-upload-button {\n    visibility: hidden;\n  }\n  .custom-file-input::before {\n      width: 30%;\n      text-align: center;\n    content: 'Actualizar';\n    color: white;\n    display: inline-block;\n    background-color: rgb(37, 37, 37);\n    border-radius: 5px;\n    padding: 10px;\n    outline: none;\n    cursor: pointer;\n    font-family: 'Montserrat';\n    font-size: 12px;\n    margin-left: 80px;\n    margin-top: 10px;\n  }\n  .custom-file-input:hover::before {\n    background-color: rgb(54, 54, 54);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
