// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.followerscard-body {
    width: 90%;
    height: 45px;
    margin: 0 auto;
    padding: 5px;
    display: flex;
    align-items: center;
}


.followerscard-button {
    width: 100px;
    padding: 5px;
    background-color: transparent;
    border: 1px solid #f36196;
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
}

.followerscard-button:hover {
    background-color: #762543;
 }

.followerscard-button-unfollow {
    background-color: transparent;
    border: 1px solid #ff60b2;
    width: 100px;
    padding: 5px;
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
 }
 
 
.followerscard-button-unfollow:hover {
    width: 120px;
    background-color: #d700271c;
    border: 1px solid #d70026;
    color: #d70026;
}


.followerscard-body p:hover {
    filter: brightness(69%);
}`, "",{"version":3,"sources":["webpack://./src/components/popup/PopupFollowers/FollowersCard.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,cAAc;IACd,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;;AAGA;IACI,YAAY;IACZ,YAAY;IACZ,6BAA6B;IAC7B,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gGAAgG;IAChG,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;CAC5B;;AAED;IACI,6BAA6B;IAC7B,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gGAAgG;IAChG,eAAe;IACf,gBAAgB;IAChB,iBAAiB;CACpB;;;AAGD;IACI,YAAY;IACZ,2BAA2B;IAC3B,yBAAyB;IACzB,cAAc;AAClB;;;AAGA;IACI,uBAAuB;AAC3B","sourcesContent":[".followerscard-body {\n    width: 90%;\n    height: 45px;\n    margin: 0 auto;\n    padding: 5px;\n    display: flex;\n    align-items: center;\n}\n\n\n.followerscard-button {\n    width: 100px;\n    padding: 5px;\n    background-color: transparent;\n    border: 1px solid #f36196;\n    color: #ededed;\n    border-radius: 5px;\n    cursor: pointer;\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;\n    font-size: 12px;\n    font-weight: 600;\n    margin-right: 5px;\n}\n\n.followerscard-button:hover {\n    background-color: #762543;\n }\n\n.followerscard-button-unfollow {\n    background-color: transparent;\n    border: 1px solid #ff60b2;\n    width: 100px;\n    padding: 5px;\n    color: #ededed;\n    border-radius: 5px;\n    cursor: pointer;\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;\n    font-size: 12px;\n    font-weight: 600;\n    margin-right: 5px;\n }\n \n \n.followerscard-button-unfollow:hover {\n    width: 120px;\n    background-color: #d700271c;\n    border: 1px solid #d70026;\n    color: #d70026;\n}\n\n\n.followerscard-body p:hover {\n    filter: brightness(69%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
