// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messages {
    width: 100%;
    overflow: scroll;
    overflow-x:hidden;
    overflow-y:hidden;
    margin-bottom: 5px;
    transition: 0.2s ease;   
    background-color: #0404048f;
}

.messages:hover {

    overflow-y:scroll;
  
}

.chat-new-messages {
    width: 90%;
    left: 13px;
    font-size: 12px;
    padding: 5px;
    border-radius: 50px;
    cursor: pointer;
    margin-top: -35px;
    background-color: #a921a2e8;
    position: absolute;
    color: #ededed;
    text-align: center;
    z-index: 99999;
}

.chat-new-messages:hover {
    background-color: #1d1d1dc5;
}

.chat-message-ban {
    width: 90%;
    margin: 0 auto;
    height: 620px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-button-view-chat {
    border: none;
    font-family: "Poppins";
    background-color: #f36196;
    padding: 5px;
    border-radius: 3px;
    color: #ededed;
    margin-top: 5px;
    cursor: pointer;
}


@media screen and (max-width: 1366px) {
    
}`, "",{"version":3,"sources":["webpack://./src/components/channel/chat/Messages.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;;IAEI,iBAAiB;;AAErB;;AAEA;IACI,UAAU;IACV,UAAU;IACV,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,2BAA2B;IAC3B,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,cAAc;IACd,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,eAAe;AACnB;;;AAGA;;AAEA","sourcesContent":[".messages {\n    width: 100%;\n    overflow: scroll;\n    overflow-x:hidden;\n    overflow-y:hidden;\n    margin-bottom: 5px;\n    transition: 0.2s ease;   \n    background-color: #0404048f;\n}\n\n.messages:hover {\n\n    overflow-y:scroll;\n  \n}\n\n.chat-new-messages {\n    width: 90%;\n    left: 13px;\n    font-size: 12px;\n    padding: 5px;\n    border-radius: 50px;\n    cursor: pointer;\n    margin-top: -35px;\n    background-color: #a921a2e8;\n    position: absolute;\n    color: #ededed;\n    text-align: center;\n    z-index: 99999;\n}\n\n.chat-new-messages:hover {\n    background-color: #1d1d1dc5;\n}\n\n.chat-message-ban {\n    width: 90%;\n    margin: 0 auto;\n    height: 620px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.chat-button-view-chat {\n    border: none;\n    font-family: \"Poppins\";\n    background-color: #f36196;\n    padding: 5px;\n    border-radius: 3px;\n    color: #ededed;\n    margin-top: 5px;\n    cursor: pointer;\n}\n\n\n@media screen and (max-width: 1366px) {\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
