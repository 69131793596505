// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.channel-gallery-body {
    width: 95%;
    margin: 0 auto;
    min-height: 500px;
    display: flex;
    color: #ededed;
}

.channel-gallery-container {
    display: flex;
    flex-wrap: wrap;
}


.channel-gallery-card {
    max-width: 285px;
    min-width: 285px;
    height: 285px;
    margin-top: 20px;
    margin-right: 25px;
    position: relative;
    cursor: pointer;
}

.channel-gallery-image-pinkker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 285px;
    height: 285px;
    position: absolute;
    top: 0;
    cursor: pointer;
    box-shadow: -1px 1px 224px 0px rgba(0,0,0,0.75) inset;
    opacity: 0;
}

.channel-gallery-card:hover .channel-gallery-image-pinkker {
    opacity: 1;

}`, "",{"version":3,"sources":["webpack://./src/components/channel/gallery/Gallery.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;;AAGA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,MAAM;IACN,eAAe;IACf,qDAAqD;IACrD,UAAU;AACd;;AAEA;IACI,UAAU;;AAEd","sourcesContent":[".channel-gallery-body {\n    width: 95%;\n    margin: 0 auto;\n    min-height: 500px;\n    display: flex;\n    color: #ededed;\n}\n\n.channel-gallery-container {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n\n.channel-gallery-card {\n    max-width: 285px;\n    min-width: 285px;\n    height: 285px;\n    margin-top: 20px;\n    margin-right: 25px;\n    position: relative;\n    cursor: pointer;\n}\n\n.channel-gallery-image-pinkker {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 285px;\n    height: 285px;\n    position: absolute;\n    top: 0;\n    cursor: pointer;\n    box-shadow: -1px 1px 224px 0px rgba(0,0,0,0.75) inset;\n    opacity: 0;\n}\n\n.channel-gallery-card:hover .channel-gallery-image-pinkker {\n    opacity: 1;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
