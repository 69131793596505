// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-body {
    display: flex;
    background-color: #080808;
    width: 100%;
    min-height: 1000px !important;
    padding: 1rem 4rem;
    flex-wrap: wrap;
}

.search-container {
    margin: 17px;
    position: relative;
    top: 69px;
    left: 20px;
    width: 71%;
}

.search-info-canales {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}


.search-info-canales-card {
    width: 150px;
    height: 220px;
    padding: 10px;
    text-align: center;
    background-color: #3030307c;
    border-radius: 5px;
    transition: 0.6s;

}

.search-info-canales-card-image {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 200px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.75);
    position: relative;
}


.search-info-canales-card:hover {
    background-color: #303030;
}

.search-info-canales>a {
    margin: 10px;
}

@media screen and (max-width: 758px) {
    .search-container {
        margin: 17px !important;
        width: 100% !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/search/Search.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,6BAA6B;IAC7B,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,UAAU;AACd;;AAEA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,eAAe;AACnB;;;AAGA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;;AAEpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,oBAAoB;IACpB,4CAA4C;IAC5C,kBAAkB;AACtB;;;AAGA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,uBAAuB;QACvB,sBAAsB;IAC1B;AACJ","sourcesContent":[".search-body {\n    display: flex;\n    background-color: #080808;\n    width: 100%;\n    min-height: 1000px !important;\n    padding: 1rem 4rem;\n    flex-wrap: wrap;\n}\n\n.search-container {\n    margin: 17px;\n    position: relative;\n    top: 69px;\n    left: 20px;\n    width: 71%;\n}\n\n.search-info-canales {\n    width: 100%;\n    height: 300px;\n    margin-top: 20px;\n    display: flex;\n    flex-wrap: wrap;\n}\n\n\n.search-info-canales-card {\n    width: 150px;\n    height: 220px;\n    padding: 10px;\n    text-align: center;\n    background-color: #3030307c;\n    border-radius: 5px;\n    transition: 0.6s;\n\n}\n\n.search-info-canales-card-image {\n    width: 140px;\n    height: 140px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 0 auto;\n    border-radius: 200px;\n    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.75);\n    position: relative;\n}\n\n\n.search-info-canales-card:hover {\n    background-color: #303030;\n}\n\n.search-info-canales>a {\n    margin: 10px;\n}\n\n@media screen and (max-width: 758px) {\n    .search-container {\n        margin: 17px !important;\n        width: 100% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
