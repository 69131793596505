// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.streammanager-activity {
    width: 100%;
    height: 336px;
    background-color: #040404;
    border: 1px solid #2b2b2b8f;
}

.activity-card-follow {
    width: 100%;
    margin: 0 auto;
    height: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ffffff1a;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/stream-manager/activity/Activity.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,cAAc;IACd,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,kCAAkC;AACtC","sourcesContent":[".streammanager-activity {\n    width: 100%;\n    height: 336px;\n    background-color: #040404;\n    border: 1px solid #2b2b2b8f;\n}\n\n.activity-card-follow {\n    width: 100%;\n    margin: 0 auto;\n    height: 20px;\n    padding: 10px;\n    display: flex;\n    align-items: center;\n    border-bottom: 1px solid #ffffff1a;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
