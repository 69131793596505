// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications-container {
    display: flex;
    width: 90%;
    padding: 9px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    justify-content: flex-start;
    overflow: hidden;
}

.notifications-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.notification-item {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 86%;
    height: 71px;
    border-bottom: 1px solid #ddd;
}

.notification-item:last-child {
    border-bottom: none;
}

.notification-avatar {
    border-radius: 50%;
    margin-right: 10px;
}

.notification-content {
    display: flex;
    flex-direction: column;
}

.notification-user {
    font-weight: bold;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.notification-link {
    text-decoration: none;
    color: inherit;
}

.notification-link:hover .notification-user {
    /* text-decoration: underline; */
}`, "",{"version":3,"sources":["webpack://./src/components/Notificaciones/Notificaciones.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,wCAAwC;IACxC,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,UAAU;IACV,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,SAAS;IACT,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".notifications-container {\n    display: flex;\n    width: 90%;\n    padding: 9px;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    justify-content: flex-start;\n    overflow: hidden;\n}\n\n.notifications-list {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n}\n\n.notification-item {\n    display: flex;\n    align-items: center;\n    padding: 10px;\n    width: 86%;\n    height: 71px;\n    border-bottom: 1px solid #ddd;\n}\n\n.notification-item:last-child {\n    border-bottom: none;\n}\n\n.notification-avatar {\n    border-radius: 50%;\n    margin-right: 10px;\n}\n\n.notification-content {\n    display: flex;\n    flex-direction: column;\n}\n\n.notification-user {\n    font-weight: bold;\n    margin: 0;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    max-width: 200px;\n}\n\n.notification-link {\n    text-decoration: none;\n    color: inherit;\n}\n\n.notification-link:hover .notification-user {\n    /* text-decoration: underline; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
