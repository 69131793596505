// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userthemes-body {
    width: 50%;
    margin-top: 50px;
}

.userthemes-container {
    width: 100%;
    background-color: rgb(22, 22, 22);
    border-radius: 5px;
    min-height: 100px;
    display: flex;
    align-items: center;
}

.userthemes-content {
    width: 32%;
    margin: 1% auto;
    text-align: center;
}

.userthemes-content button{
   width: 50%;
   padding: 15px;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   font-family: 'Poppins';
}


.userthemes-active button{
    border: 1px solid #ff60b2;
}`, "",{"version":3,"sources":["webpack://./src/components/settings/user/themes/Themes.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,iCAAiC;IACjC,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,kBAAkB;AACtB;;AAEA;GACG,UAAU;GACV,aAAa;GACb,YAAY;GACZ,kBAAkB;GAClB,eAAe;GACf,sBAAsB;AACzB;;;AAGA;IACI,yBAAyB;AAC7B","sourcesContent":[".userthemes-body {\n    width: 50%;\n    margin-top: 50px;\n}\n\n.userthemes-container {\n    width: 100%;\n    background-color: rgb(22, 22, 22);\n    border-radius: 5px;\n    min-height: 100px;\n    display: flex;\n    align-items: center;\n}\n\n.userthemes-content {\n    width: 32%;\n    margin: 1% auto;\n    text-align: center;\n}\n\n.userthemes-content button{\n   width: 50%;\n   padding: 15px;\n   border: none;\n   border-radius: 5px;\n   cursor: pointer;\n   font-family: 'Poppins';\n}\n\n\n.userthemes-active button{\n    border: 1px solid #ff60b2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
