// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordionlist-body {
    width: 100%;
    height: 750px;
}

.accordionlist-item {
    width: 90%;
    padding: 10px;
    border-top: 1px solid #151515;
    border-bottom: 1px solid #151515;

    margin: 0 auto;
}

.accordionlist-item-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: 20px;

}

.accordionlist-item-content {
    transition: 1s all;
    margin-bottom: 20px;
}

.chat-users-card {
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}


.chat-users-card h4:hover{ 
    border-bottom: 1px solid #ededed;
}`, "",{"version":3,"sources":["webpack://./src/components/channel/chat/accordion/AccordionList.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,6BAA6B;IAC7B,gCAAgC;;IAEhC,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;;AAEpB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,8BAA8B;AAClC;;;AAGA;IACI,gCAAgC;AACpC","sourcesContent":[".accordionlist-body {\n    width: 100%;\n    height: 750px;\n}\n\n.accordionlist-item {\n    width: 90%;\n    padding: 10px;\n    border-top: 1px solid #151515;\n    border-bottom: 1px solid #151515;\n\n    margin: 0 auto;\n}\n\n.accordionlist-item-title {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    cursor: pointer;\n    margin-bottom: 5px;\n    margin-top: 20px;\n\n}\n\n.accordionlist-item-content {\n    transition: 1s all;\n    margin-bottom: 20px;\n}\n\n.chat-users-card {\n    width: 100%;\n    margin: 0 auto;\n    border-radius: 5px;\n    margin-top: 15px;\n    cursor: pointer;\n    display: flex;\n    justify-content: space-between;\n}\n\n\n.chat-users-card h4:hover{ \n    border-bottom: 1px solid #ededed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
