// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminstatistics-body {
    width: 100%;
    min-height: 1000px;
    background-color: #0A0B0D;
}

.adminstatistics-container {
    width: 95%;
    margin: 0 auto;
}

.adminstatistics-card-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
}

.adminstatistics-card {
    width: 250px;
    background-color: #151515;
    height: 100px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ededed;
    text-align: center;
    border-radius: 5px;
}

.adminstatistics-card:hover {
    background-color: #303030;
}
`, "",{"version":3,"sources":["webpack://./src/components/admin/statistics/Statistics.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".adminstatistics-body {\n    width: 100%;\n    min-height: 1000px;\n    background-color: #0A0B0D;\n}\n\n.adminstatistics-container {\n    width: 95%;\n    margin: 0 auto;\n}\n\n.adminstatistics-card-container {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    margin-top: 50px;\n}\n\n.adminstatistics-card {\n    width: 250px;\n    background-color: #151515;\n    height: 100px;\n    margin-right: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #ededed;\n    text-align: center;\n    border-radius: 5px;\n}\n\n.adminstatistics-card:hover {\n    background-color: #303030;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
