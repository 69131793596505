// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-left-mobile {
    width: 100%;
    height: 100px;
    background-color: #0a0a0a;
    position: fixed;
    z-index: 999999;
    bottom: 0;
    color: white;
    border-top: 2px solid #5f5f5f8f;
}


.navbar-left-mobile-container {
    display: flex;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
}

.navbar-left-mobile-card {
    width: 16%;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}
`, "",{"version":3,"sources":["webpack://./src/components/navbarLeft/navbarLeftMobile.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,eAAe;IACf,eAAe;IACf,SAAS;IACT,YAAY;IACZ,+BAA+B;AACnC;;;AAGA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;AAE3B","sourcesContent":[".navbar-left-mobile {\n    width: 100%;\n    height: 100px;\n    background-color: #0a0a0a;\n    position: fixed;\n    z-index: 999999;\n    bottom: 0;\n    color: white;\n    border-top: 2px solid #5f5f5f8f;\n}\n\n\n.navbar-left-mobile-container {\n    display: flex;\n    margin-top: 5px;\n    justify-content: center;\n    align-items: center;\n}\n\n.navbar-left-mobile-card {\n    width: 16%;\n    padding: 10px;\n    text-align: center;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
