// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usersettings-channel-body {
    height: 800px;
    width: 95%;
    margin: 0 auto;
}

.usersettings-channel-container {

}

.usersettings-channel-card {
    width: 70%;
}

.usersettings-channel-card-content {
    background-color: #0404048f;
    width: 90%;
    border-radius: 5px;
    margin-top: 10px;
    border: 1px solid #ffffff1a;
}

.usersettings-channel-card-content-card {
    padding: 20px;
    border-bottom: 1px solid #ffffff1a;
    min-height: 50px;
    display: flex;
}

.usersettings-channel-card-content-card-title {
    width: 30%;
}

.usersettings-channel-card-content-card-text {
    width: 55%;
}

.usersettings-channel-card-content-card-edit {
    width: 15%;
}

@media screen and (max-width: 758px) {
    .usersettings-channel-card {
        width: 100%;
    }

    .usersettings-channel-card-content {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/settings/user/channel/ChannelSettings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,cAAc;AAClB;;AAEA;;AAEA;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,2BAA2B;IAC3B,UAAU;IACV,kBAAkB;IAClB,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,kCAAkC;IAClC,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".usersettings-channel-body {\n    height: 800px;\n    width: 95%;\n    margin: 0 auto;\n}\n\n.usersettings-channel-container {\n\n}\n\n.usersettings-channel-card {\n    width: 70%;\n}\n\n.usersettings-channel-card-content {\n    background-color: #0404048f;\n    width: 90%;\n    border-radius: 5px;\n    margin-top: 10px;\n    border: 1px solid #ffffff1a;\n}\n\n.usersettings-channel-card-content-card {\n    padding: 20px;\n    border-bottom: 1px solid #ffffff1a;\n    min-height: 50px;\n    display: flex;\n}\n\n.usersettings-channel-card-content-card-title {\n    width: 30%;\n}\n\n.usersettings-channel-card-content-card-text {\n    width: 55%;\n}\n\n.usersettings-channel-card-content-card-edit {\n    width: 15%;\n}\n\n@media screen and (max-width: 758px) {\n    .usersettings-channel-card {\n        width: 100%;\n    }\n\n    .usersettings-channel-card-content {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
