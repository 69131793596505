// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stream-messages {
    width: 100%;
    height: 730px;
    overflow: scroll;
    overflow-x:hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/stream-manager/chat/Messages.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".stream-messages {\n    width: 100%;\n    height: 730px;\n    overflow: scroll;\n    overflow-x:hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
