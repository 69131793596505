// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-buttom {
    width: 88.5%;
    margin-left: 11.5%;
    height: 65px;
    top: 93%;
    background: rgb(243,97,150);
    background: linear-gradient(90deg, rgb(194, 56, 107) 0%, rgba(171,59,99,1) 35%, rgb(197, 53, 106) 69%, rgba(255,33,113,1) 100%);    
    position: fixed;
    z-index: 9999;
    color: #ededed;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px black;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-boton {
    padding: 7px;
    border: none;
    font-family: 'Montserrat';
    font-weight: 500;
    border-radius: 5px;
}

.navbar-boton:hover {
    background-color: rgb(204, 204, 204);
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .navbar-buttom {
        width: 92%;
        margin-left: 8%;
        top: 94%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/navbarBottom/navbarButtom.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,QAAQ;IACR,2BAA2B;IAC3B,+HAA+H;IAC/H,eAAe;IACf,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,iCAAiC;IACjC,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;IACpC,eAAe;AACnB;;AAEA;IACI;QACI,UAAU;QACV,eAAe;QACf,QAAQ;IACZ;AACJ","sourcesContent":[".navbar-buttom {\n    width: 88.5%;\n    margin-left: 11.5%;\n    height: 65px;\n    top: 93%;\n    background: rgb(243,97,150);\n    background: linear-gradient(90deg, rgb(194, 56, 107) 0%, rgba(171,59,99,1) 35%, rgb(197, 53, 106) 69%, rgba(255,33,113,1) 100%);    \n    position: fixed;\n    z-index: 9999;\n    color: #ededed;\n    border-radius: 5px;\n    box-shadow: 1px 1px 1px 1px black;\n    font-size: 1.2rem;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.navbar-boton {\n    padding: 7px;\n    border: none;\n    font-family: 'Montserrat';\n    font-weight: 500;\n    border-radius: 5px;\n}\n\n.navbar-boton:hover {\n    background-color: rgb(204, 204, 204);\n    cursor: pointer;\n}\n\n@media screen and (max-width: 768px) {\n    .navbar-buttom {\n        width: 92%;\n        margin-left: 8%;\n        top: 94%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
