// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tendency-body {
    width: 100%;
    min-height: 1000px;
    background-color: transparent;
}

.tendency-container {
    width: 95%;
    min-height: 1000px;
    margin: 0 auto;
    display: block;
    justify-content: left;
    position: relative;
    padding: 2rem 8rem;
    background: #080808;
}



.tendency-title {
    display: flex;
    align-items: center;
    color: #ededed;

}

.tendency-card-container {
    margin-top: 100px;
}

.tendency-card-container-clips {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tendency-card-container-streams {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 9rem;
}

.home-categories-p-1-name {
    font-size: 15px;
    font-family: "Inter";
    font-weight: "300";
    letter-spacing: "0.6px";
    margin-top: "3px";
    cursor: "pointer";
}

@media screen and (max-width: 758px) {
    .tendency-body {
        min-height: 1500px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/tendency/Tendency.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,cAAc;IACd,cAAc;IACd,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;AACvB;;;;AAIA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;;AAElB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,kBAAkB;IAClB,uBAAuB;IACvB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".tendency-body {\n    width: 100%;\n    min-height: 1000px;\n    background-color: transparent;\n}\n\n.tendency-container {\n    width: 95%;\n    min-height: 1000px;\n    margin: 0 auto;\n    display: block;\n    justify-content: left;\n    position: relative;\n    padding: 2rem 8rem;\n    background: #080808;\n}\n\n\n\n.tendency-title {\n    display: flex;\n    align-items: center;\n    color: #ededed;\n\n}\n\n.tendency-card-container {\n    margin-top: 100px;\n}\n\n.tendency-card-container-clips {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.tendency-card-container-streams {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    padding-right: 9rem;\n}\n\n.home-categories-p-1-name {\n    font-size: 15px;\n    font-family: \"Inter\";\n    font-weight: \"300\";\n    letter-spacing: \"0.6px\";\n    margin-top: \"3px\";\n    cursor: \"pointer\";\n}\n\n@media screen and (max-width: 758px) {\n    .tendency-body {\n        min-height: 1500px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
