// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-message {
    min-width: 90%;
    max-width: 90%;
    padding: 5px;
    margin: 0 auto;
    overflow-y: scroll;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
}

.chat-message:hover {
    background-color: #2b2b2b3f;
    border-radius: 5px;
}

.chat-message-user-hover{
    margin-left: 3px;
    padding: 2px;
}

.chat-message-user-hover:hover {
    background-color: #303030;
    padding: 2px;
    border-radius: 3px;
}

.chat-message-system {
    width: 90%;
    padding: 5px;
    margin: 0 auto;
}

.message-chat-links {
    color: #ff60b2;
    text-decoration: none;
}

.message-chat-links:hover {
    text-decoration: underline;
}

.message-chat-donation {
    width: 85%;
    height: 30px;
    margin: 5px auto;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}



/* V2 */

.chat-message-v2 {
    background-color: blue;
    max-width: 350px;
    display: flex;

}


.chat-message-icons-hover {
    position: absolute;
    right: 15px;
    margin-top: -30px;
}

.chat-message-icons-hover i{
    background-color: #303030;
    padding: 5px;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/channel/chat/Message.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,cAAc;IACd,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,qCAAqC;AACzC;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;;;AAIA,OAAO;;AAEP;IACI,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;;AAEjB;;;AAGA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".chat-message {\n    min-width: 90%;\n    max-width: 90%;\n    padding: 5px;\n    margin: 0 auto;\n    overflow-y: scroll;\n    overflow-wrap: break-word;\n    -webkit-line-break: after-white-space;\n}\n\n.chat-message:hover {\n    background-color: #2b2b2b3f;\n    border-radius: 5px;\n}\n\n.chat-message-user-hover{\n    margin-left: 3px;\n    padding: 2px;\n}\n\n.chat-message-user-hover:hover {\n    background-color: #303030;\n    padding: 2px;\n    border-radius: 3px;\n}\n\n.chat-message-system {\n    width: 90%;\n    padding: 5px;\n    margin: 0 auto;\n}\n\n.message-chat-links {\n    color: #ff60b2;\n    text-decoration: none;\n}\n\n.message-chat-links:hover {\n    text-decoration: underline;\n}\n\n.message-chat-donation {\n    width: 85%;\n    height: 30px;\n    margin: 5px auto;\n    padding: 10px;\n    border-radius: 5px;\n    display: flex;\n    align-items: center;\n}\n\n\n\n/* V2 */\n\n.chat-message-v2 {\n    background-color: blue;\n    max-width: 350px;\n    display: flex;\n\n}\n\n\n.chat-message-icons-hover {\n    position: absolute;\n    right: 15px;\n    margin-top: -30px;\n}\n\n.chat-message-icons-hover i{\n    background-color: #303030;\n    padding: 5px;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
