// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-body {
    color: #ededed;
    width: 100%;
    margin-bottom: 50px;
}

.comment-user {
    display: flex;
}

.comment-button-reply {
    margin-left: 20px;
    background-color: transparent;
    font-size: 12px;
    border: none;
    color: darkgray;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}
.comment-button-view-reply {
    height: 35px;
    background-color: transparent;
    color: #ff60b2;
    font-weight: 600;
    border: none;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/vod/comments/Comment.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,6BAA6B;IAC7B,eAAe;IACf,YAAY;IACZ,eAAe;IACf,qCAAqC;IACrC,eAAe;AACnB;AACA;IACI,YAAY;IACZ,6BAA6B;IAC7B,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".comment-body {\n    color: #ededed;\n    width: 100%;\n    margin-bottom: 50px;\n}\n\n.comment-user {\n    display: flex;\n}\n\n.comment-button-reply {\n    margin-left: 20px;\n    background-color: transparent;\n    font-size: 12px;\n    border: none;\n    color: darkgray;\n    font-family: 'Montserrat', sans-serif;\n    cursor: pointer;\n}\n.comment-button-view-reply {\n    height: 35px;\n    background-color: transparent;\n    color: #ff60b2;\n    font-weight: 600;\n    border: none;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
