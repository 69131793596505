// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cliptendencycard-body {
    width: 380px;
    height: 320px;
    display: flex;
    margin: 10px;
    background-color: #303030;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    flex-direction: column;
}

.cliptendencycard-image {
    width: 15%;

}

.cliptendencycard-info {
    width: 49%;
    height: 100%;
    color: #ededed;
}

.cliptendencycard-body:hover {
    background-color: #303030;
}`, "",{"version":3,"sources":["webpack://./src/components/tendency/card/ClipTendencyCard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,UAAU;;AAEd;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".cliptendencycard-body {\n    width: 380px;\n    height: 320px;\n    display: flex;\n    margin: 10px;\n    background-color: #303030;\n    padding: 20px;\n    border-radius: 5px;\n    cursor: pointer;\n    flex-direction: column;\n}\n\n.cliptendencycard-image {\n    width: 15%;\n\n}\n\n.cliptendencycard-info {\n    width: 49%;\n    height: 100%;\n    color: #ededed;\n}\n\n.cliptendencycard-body:hover {\n    background-color: #303030;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
