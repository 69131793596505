// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
    display: flex;
    justify-content: space-between;
    width: 700px;
}

.button-container button {
    margin: 0px;
}

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.renderActivePanel-main {
    margin: 10px 0px;
    display: flex;
    background: #f361961c;
}

.categories-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.category-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.category-img {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0 0;
}

.category-name {
    font-size: 1.2em;
    margin: 10px 0;
}

.category-topcolor {
    height: 10px;
    border-radius: 0 0 10px 10px;
}

.EditarCategoria {
    background: #121418;
    position: fixed;
    z-index: 1000;
    padding: 10px;
}

.FindUsersPanelFindUsersPanelForm>form {
    display: flex;
    width: 700px;
}`, "",{"version":3,"sources":["webpack://./src/components/panelAdminPinkker/Main.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB","sourcesContent":[".button-container {\n    display: flex;\n    justify-content: space-between;\n    width: 700px;\n}\n\n.button-container button {\n    margin: 0px;\n}\n\n.main-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n}\n\n.renderActivePanel-main {\n    margin: 10px 0px;\n    display: flex;\n    background: #f361961c;\n}\n\n.categories-container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 20px;\n    justify-content: center;\n}\n\n.category-card {\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    padding: 10px;\n    text-align: center;\n    width: 200px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.category-img {\n    width: 100%;\n    height: auto;\n    border-radius: 10px 10px 0 0;\n}\n\n.category-name {\n    font-size: 1.2em;\n    margin: 10px 0;\n}\n\n.category-topcolor {\n    height: 10px;\n    border-radius: 0 0 10px 10px;\n}\n\n.EditarCategoria {\n    background: #121418;\n    position: fixed;\n    z-index: 1000;\n    padding: 10px;\n}\n\n.FindUsersPanelFindUsersPanelForm>form {\n    display: flex;\n    width: 700px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
