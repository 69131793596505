// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mostrarpopup-popup-body {
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0,0,0, 0.5);
    z-index: 9999;
   
}

.mostrarpopup-popup-container {
    width: 35%;
    height: 300px;
	margin: auto;
    padding: 30px;
	background: #18181b;
	border-radius: 5px;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
}

.mostrarpopup-popup-button {
    width: 50%;
    margin: 0 auto;
    height: 50px;
    margin-top: 20px;
    background: #18181b;
    border: 1px solid #ededed;
    border-radius: 5px;
    color: #ededed;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.mostrarpopup-popup-button:hover {
    background: #ff64b0;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/stream-manager/popup/MostrarPopup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;CAClB,WAAW;CACX,YAAY;CACZ,MAAM;CACN,OAAO;CACP,QAAQ;CACR,SAAS;CACT,YAAY;CACZ,kCAAkC;IAC/B,aAAa;;AAEjB;;AAEA;IACI,UAAU;IACV,aAAa;CAChB,YAAY;IACT,aAAa;CAChB,mBAAmB;CACnB,kBAAkB;IACf,iDAAiD;AACrD;;AAEA;IACI,UAAU;IACV,cAAc;IACd,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".mostrarpopup-popup-body {\n    position: fixed;\n\twidth: 100%;\n\theight: 100%;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tmargin: auto;\n\tbackground-color: rgba(0,0,0, 0.5);\n    z-index: 9999;\n   \n}\n\n.mostrarpopup-popup-container {\n    width: 35%;\n    height: 300px;\n\tmargin: auto;\n    padding: 30px;\n\tbackground: #18181b;\n\tborder-radius: 5px;\n    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);\n}\n\n.mostrarpopup-popup-button {\n    width: 50%;\n    margin: 0 auto;\n    height: 50px;\n    margin-top: 20px;\n    background: #18181b;\n    border: 1px solid #ededed;\n    border-radius: 5px;\n    color: #ededed;\n    font-size: 20px;\n    font-weight: bold;\n    cursor: pointer;\n}\n\n.mostrarpopup-popup-button:hover {\n    background: #ff64b0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
