// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emblem-img {
    display: inline-block;
    margin-right: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/emblem/Emblem.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iBAAiB;AACrB","sourcesContent":[".emblem-img {\n    display: inline-block;\n    margin-right: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
