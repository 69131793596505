// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.channel-about-body {
    width: 95%;
    margin: 0 auto;
    min-height: 500px;
    display: flex;
    background-color: transparent;
    margin-bottom: 0px;
    border-radius: 10px;
    padding: 0px;
    color: #ededed;
    margin-top: 20px;
}

.channel-about-primary {
    width: 28%;
    height: 180px;
    padding: 20px;
    background-color: #0404048f;
    border-radius: 10px;
}

.channel-about-secondary {
    padding: 20px;
    border-radius: 10px;
    width: 70%;
    margin-left: 3%;
    height: 500px;
    background-color: #0404048f;
}

.channel-about-primary-card {
    width: 50%;
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
}

.channel-about-primary-card:hover {
    background-color: #303030;
}

.channel-about-primary-card-active {
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    background-color: #303030;
}

.channel-about-secondary-card {
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 5px;
}

.channel-about-secondary-card:hover {
    cursor: pointer;
}

.channel-about-emblem-container {
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/channel/about/About.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,6BAA6B;IAC7B,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,eAAe;IACf,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".channel-about-body {\n    width: 95%;\n    margin: 0 auto;\n    min-height: 500px;\n    display: flex;\n    background-color: transparent;\n    margin-bottom: 0px;\n    border-radius: 10px;\n    padding: 0px;\n    color: #ededed;\n    margin-top: 20px;\n}\n\n.channel-about-primary {\n    width: 28%;\n    height: 180px;\n    padding: 20px;\n    background-color: #0404048f;\n    border-radius: 10px;\n}\n\n.channel-about-secondary {\n    padding: 20px;\n    border-radius: 10px;\n    width: 70%;\n    margin-left: 3%;\n    height: 500px;\n    background-color: #0404048f;\n}\n\n.channel-about-primary-card {\n    width: 50%;\n    padding: 5px;\n    border-radius: 5px;\n    font-size: 16px;\n    cursor: pointer;\n    margin-top: 10px;\n}\n\n.channel-about-primary-card:hover {\n    background-color: #303030;\n}\n\n.channel-about-primary-card-active {\n    padding: 5px;\n    border-radius: 5px;\n    font-size: 16px;\n    cursor: pointer;\n    margin-top: 10px;\n    background-color: #303030;\n}\n\n.channel-about-secondary-card {\n    margin-bottom: 20px;\n    border-radius: 5px;\n    padding: 5px;\n}\n\n.channel-about-secondary-card:hover {\n    cursor: pointer;\n}\n\n.channel-about-emblem-container {\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
