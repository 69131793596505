// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.citetweet-popup-body {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;

}

.citetweet-popup-container {
    width: 35%;
    min-height: 400px;
    overflow: scroll;
    margin: auto;
    padding: 30px;
    text-align: center;
    background: #18181b;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
}

#citetweet-textarea {
    background-color: #3a3b3c;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0px 10px;
    border-radius: 5px;
    width: 90%;
}

.citetweet-popup-primary {
    display: flex;
}

.citetweet-popup-avatar {
    width: 70px;
}

.citetweet-popup-tweet {
    width: 95%;
    min-height: 75px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ffffff1a;
    text-align: left;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/muro/popup/CiteTweet.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,oCAAoC;IACpC,aAAa;;AAEjB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,iDAAiD;AACrD;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,2BAA2B;IAC3B,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".citetweet-popup-body {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    margin: auto;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 9999;\n\n}\n\n.citetweet-popup-container {\n    width: 35%;\n    min-height: 400px;\n    overflow: scroll;\n    margin: auto;\n    padding: 30px;\n    text-align: center;\n    background: #18181b;\n    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);\n}\n\n#citetweet-textarea {\n    background-color: #3a3b3c;\n    display: flex;\n    align-items: center;\n    height: 40px;\n    padding: 0px 10px;\n    border-radius: 5px;\n    width: 90%;\n}\n\n.citetweet-popup-primary {\n    display: flex;\n}\n\n.citetweet-popup-avatar {\n    width: 70px;\n}\n\n.citetweet-popup-tweet {\n    width: 95%;\n    min-height: 75px;\n    padding: 15px;\n    border-radius: 5px;\n    border: 1px solid #ffffff1a;\n    text-align: left;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
