// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emotes-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #080808;
    width: 700px;
}

.emotes-grid {
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    width: 820px;
    /* height: 180px; */
}

.emote-card {
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 2px;
    border-radius: 3px;
    margin-top: 7px;
    margin-right: 4px;
    background-color: #121418;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.emote-card:hover {
    transform: translateY(-5px);
}

.emote-card img {
    width: 37%;
    height: auto;
    border-radius: 8px;
}

.emote-card span {
    display: block;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 10px;
}

.emote-buttons button {
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 5px;
}

.emote-buttons button:hover {
    background-color: #cc0000;
}`, "",{"version":3,"sources":["webpack://./src/components/panelAdminPinkker/Emotes.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,aAAa;IACb,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,wCAAwC;IACxC,sCAAsC;AAC1C;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".emotes-container {\n    font-family: Arial, sans-serif;\n    padding: 20px;\n    background-color: #080808;\n    width: 700px;\n}\n\n.emotes-grid {\n    display: flex;\n    flex-wrap: wrap;\n    overflow: scroll;\n    width: 820px;\n    /* height: 180px; */\n}\n\n.emote-card {\n    width: 260px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    padding: 2px;\n    border-radius: 3px;\n    margin-top: 7px;\n    margin-right: 4px;\n    background-color: #121418;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    transition: transform 0.3s ease-in-out;\n}\n\n.emote-card:hover {\n    transform: translateY(-5px);\n}\n\n.emote-card img {\n    width: 37%;\n    height: auto;\n    border-radius: 8px;\n}\n\n.emote-card span {\n    display: block;\n    font-size: 16px;\n    color: #ffffff;\n    margin-bottom: 10px;\n}\n\n.emote-buttons button {\n    background-color: #ff4d4d;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    padding: 5px 10px;\n    font-size: 14px;\n    cursor: pointer;\n    margin-right: 5px;\n}\n\n.emote-buttons button:hover {\n    background-color: #cc0000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
