// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Canalrecomendado:hover{
    cursor: pointer;
    --tw-bg-opacity: 1;
    background-color: rgb(36 39 44 / var(--tw-bg-opacity));
    border-radius: 4px;
}

.Canalrecomendado:hover::after {
    position: absolute;
    right: -95px;
    background-color: #3b3b44;
    color:white;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    padding-left:  0.5rem !important;
    padding-right:  0.5rem !important;
    border-radius: 5px;
    z-index: 999999999999;
    content: attr(data-title); /* Utiliza el valor de la variable */
    /* Otras propiedades CSS para :after si es necesario */
  }`, "",{"version":3,"sources":["webpack://./src/components/layout/CanalesRecomendados.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,sDAAsD;IACtD,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,WAAW;IACX,8CAA8C;IAC9C,gCAAgC;IAChC,iCAAiC;IACjC,kBAAkB;IAClB,qBAAqB;IACrB,yBAAyB,EAAE,oCAAoC;IAC/D,sDAAsD;EACxD","sourcesContent":[".Canalrecomendado:hover{\n    cursor: pointer;\n    --tw-bg-opacity: 1;\n    background-color: rgb(36 39 44 / var(--tw-bg-opacity));\n    border-radius: 4px;\n}\n\n.Canalrecomendado:hover::after {\n    position: absolute;\n    right: -95px;\n    background-color: #3b3b44;\n    color:white;\n    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);\n    padding-left:  0.5rem !important;\n    padding-right:  0.5rem !important;\n    border-radius: 5px;\n    z-index: 999999999999;\n    content: attr(data-title); /* Utiliza el valor de la variable */\n    /* Otras propiedades CSS para :after si es necesario */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
