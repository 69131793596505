// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usersettings-prime-body {
    height: 700px;
    width: 95%;
    margin: 0 auto;
}

.usersettings-prime-container {
    background-color: #0404048f;
    padding: 30px;
    border-radius: 10px;
    width: 40%;
    height: 600px;
    text-align: justify;
}

.usersettings-prime-button {
    margin-top: 20px;
    width: 110px;
    padding: 5px;
    background-color: transparent;
    border: 1px solid #f36196;
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
}

.pinkker-chat-naming-prime {
    background: url("https://res.cloudinary.com/pinkker/image/upload/v1677784906/naming/colaborador_qwwmv6.gif") no-repeat;
    background-position-y: -10px;
}


@media screen and (max-width: 758px) {
    .usersettings-prime-container {
        width: 90%;
    }

    .usersettings-prime-button {
        width: 130px;
        font-size: 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/settings/user/prime/Prime.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,6BAA6B;IAC7B,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gGAAgG;IAChG,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,sHAAsH;IACtH,4BAA4B;AAChC;;;AAGA;IACI;QACI,UAAU;IACd;;IAEA;QACI,YAAY;QACZ,eAAe;IACnB;AACJ","sourcesContent":[".usersettings-prime-body {\n    height: 700px;\n    width: 95%;\n    margin: 0 auto;\n}\n\n.usersettings-prime-container {\n    background-color: #0404048f;\n    padding: 30px;\n    border-radius: 10px;\n    width: 40%;\n    height: 600px;\n    text-align: justify;\n}\n\n.usersettings-prime-button {\n    margin-top: 20px;\n    width: 110px;\n    padding: 5px;\n    background-color: transparent;\n    border: 1px solid #f36196;\n    color: #ededed;\n    border-radius: 5px;\n    cursor: pointer;\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;\n    font-size: 12px;\n    font-weight: 600;\n    margin-right: 5px;\n}\n\n.pinkker-chat-naming-prime {\n    background: url(\"https://res.cloudinary.com/pinkker/image/upload/v1677784906/naming/colaborador_qwwmv6.gif\") no-repeat;\n    background-position-y: -10px;\n}\n\n\n@media screen and (max-width: 758px) {\n    .usersettings-prime-container {\n        width: 90%;\n    }\n\n    .usersettings-prime-button {\n        width: 130px;\n        font-size: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
