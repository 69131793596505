// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecoverPasswordContainer {
    width: 51%;
    height: max-content;
    padding: 30px;
    text-align: center;
    background: #18181b;
    position: absolute;
    top: 200px;
    left: 20%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);
    display: flex;
    z-index: 9999;
}

.RecoverPassword {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.RecoverPasswordInputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
}

.RecoverPasswordInputs input {
    background-color: rgb(65, 65, 65);
    border: none;
    padding: 7px;
    width: 100%;
    font-size: 12px;
    border-radius: 5px;
    color: #ededed;
}`, "",{"version":3,"sources":["webpack://./src/components/userRecuperacion/RecoverPassword.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,2BAA2B;IAC3B,8BAA8B;IAC9B,iDAAiD;IACjD,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,iCAAiC;IACjC,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".RecoverPasswordContainer {\n    width: 51%;\n    height: max-content;\n    padding: 30px;\n    text-align: center;\n    background: #18181b;\n    position: absolute;\n    top: 200px;\n    left: 20%;\n    border-top-left-radius: 5px;\n    border-bottom-left-radius: 5px;\n    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.651);\n    display: flex;\n    z-index: 9999;\n}\n\n.RecoverPassword {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.RecoverPasswordInputs {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 60%;\n}\n\n.RecoverPasswordInputs input {\n    background-color: rgb(65, 65, 65);\n    border: none;\n    padding: 7px;\n    width: 100%;\n    font-size: 12px;\n    border-radius: 5px;\n    color: #ededed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
