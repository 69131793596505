// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dropdownnews-menu {
    position: absolute;
    top: 52px;
    right: 300px;
    display: flex;
    background: #0a0a0afc;
    border: 1px solid #ffffff1a;
    box-shadow: 0px 5px 20px 5px black;
    border-radius: 5px;
    font-family: 'Roboto';
    width: 350px;
}
    
.dropdownnews-menu li {
  display: flex;
  width: 95%;
  font-size: 12px;
  border-bottom: 1px solid #4b4b4b8f;
  padding: 10px;
  cursor: pointer   ;
}
    

.dropdownnews-news-li:hover {
  background-color: #0e0e0e;
  
}
    
.dropdownnews-menu.clicked {
    display: none;
}
    
.dropdownnews-button {
      background-color: #f36196;
      border: none;
      margin-top: 20px;
      width: 50%;
      font-family: 'Montserrat';
      font-weight: 800;
      padding: 5px;
      color: #ededed;
      cursor: pointer;
      border-radius: 3px;

}

.dropdownnews-news-card {
    width: 100%;
}
    
@media screen and (max-width: 960px) {
  
      .dropdownnews-menu {
        width: 220%;
        top: 60px;
        z-index: 200;
  
      }
  
      .fa-caret-down {
        display: none;
      }
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/news/DropdownNews.css"],"names":[],"mappings":";AAGA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,2BAA2B;IAC3B,kCAAkC;IAClC,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,eAAe;EACf,kCAAkC;EAClC,aAAa;EACb,kBAAkB;AACpB;;;AAGA;EACE,yBAAyB;;AAE3B;;AAEA;IACI,aAAa;AACjB;;AAEA;MACM,yBAAyB;MACzB,YAAY;MACZ,gBAAgB;MAChB,UAAU;MACV,yBAAyB;MACzB,gBAAgB;MAChB,YAAY;MACZ,cAAc;MACd,eAAe;MACf,kBAAkB;;AAExB;;AAEA;IACI,WAAW;AACf;;AAEA;;MAEM;QACE,WAAW;QACX,SAAS;QACT,YAAY;;MAEd;;MAEA;QACE,aAAa;MACf;AACN","sourcesContent":["\n@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');\n\n.dropdownnews-menu {\n    position: absolute;\n    top: 52px;\n    right: 300px;\n    display: flex;\n    background: #0a0a0afc;\n    border: 1px solid #ffffff1a;\n    box-shadow: 0px 5px 20px 5px black;\n    border-radius: 5px;\n    font-family: 'Roboto';\n    width: 350px;\n}\n    \n.dropdownnews-menu li {\n  display: flex;\n  width: 95%;\n  font-size: 12px;\n  border-bottom: 1px solid #4b4b4b8f;\n  padding: 10px;\n  cursor: pointer   ;\n}\n    \n\n.dropdownnews-news-li:hover {\n  background-color: #0e0e0e;\n  \n}\n    \n.dropdownnews-menu.clicked {\n    display: none;\n}\n    \n.dropdownnews-button {\n      background-color: #f36196;\n      border: none;\n      margin-top: 20px;\n      width: 50%;\n      font-family: 'Montserrat';\n      font-weight: 800;\n      padding: 5px;\n      color: #ededed;\n      cursor: pointer;\n      border-radius: 3px;\n\n}\n\n.dropdownnews-news-card {\n    width: 100%;\n}\n    \n@media screen and (max-width: 960px) {\n  \n      .dropdownnews-menu {\n        width: 220%;\n        top: 60px;\n        z-index: 200;\n  \n      }\n  \n      .fa-caret-down {\n        display: none;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
