// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loading.css */

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #080808; /* Puedes ajustar el color de fondo según el diseño de tu aplicación */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999; /* Asegúrate de que esté en un z-index alto para que esté por encima de otros elementos */
    opacity: 1;
    transition: opacity 0.5s ease; /* Define la transición de opacidad */
}

.logo {
    width: 25%;
   
}


/* Dispositivos pequeños (teléfonos en modo vertical) */
@media (max-width: 575.98px) {
    /* Estilos para dispositivos pequeños */
    .logo {
        width: 50% !important;
       
    }
  }
  
  /* Dispositivos pequeños (teléfonos en modo horizontal) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    /* Estilos para dispositivos pequeños en modo horizontal */
    .logo {
        width: 50% !important;
        
        
    }
  }
  .rotate-animation {
    animation: rotate 1s infinite linear; /* Define la animación de giro */
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loading-container.fade-out {
    opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/loading.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,yBAAyB,EAAE,sEAAsE;IACjG,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,aAAa,EAAE,yFAAyF;IACxG,UAAU;IACV,6BAA6B,EAAE,qCAAqC;AACxE;;AAEA;IACI,UAAU;;AAEd;;;AAGA,uDAAuD;AACvD;IACI,uCAAuC;IACvC;QACI,qBAAqB;;IAEzB;EACF;;EAEA,yDAAyD;EACzD;IACE,0DAA0D;IAC1D;QACI,qBAAqB;;;IAGzB;EACF;EACA;IACE,oCAAoC,EAAE,gCAAgC;AAC1E;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,UAAU;AACd","sourcesContent":["/* Loading.css */\n\n.loading-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    width: 100%;\n    background-color: #080808; /* Puedes ajustar el color de fondo según el diseño de tu aplicación */\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: 9999; /* Asegúrate de que esté en un z-index alto para que esté por encima de otros elementos */\n    opacity: 1;\n    transition: opacity 0.5s ease; /* Define la transición de opacidad */\n}\n\n.logo {\n    width: 25%;\n   \n}\n\n\n/* Dispositivos pequeños (teléfonos en modo vertical) */\n@media (max-width: 575.98px) {\n    /* Estilos para dispositivos pequeños */\n    .logo {\n        width: 50% !important;\n       \n    }\n  }\n  \n  /* Dispositivos pequeños (teléfonos en modo horizontal) */\n  @media (min-width: 576px) and (max-width: 767.98px) {\n    /* Estilos para dispositivos pequeños en modo horizontal */\n    .logo {\n        width: 50% !important;\n        \n        \n    }\n  }\n  .rotate-animation {\n    animation: rotate 1s infinite linear; /* Define la animación de giro */\n}\n\n@keyframes rotate {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}\n\n.loading-container.fade-out {\n    opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
