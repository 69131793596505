// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suscriptions-body {
    width: 100%;
    min-height: 1000px;
    background-color: #0A0B0D;
}

.suscriptions-container {
    width: 95%;
    margin: 0 auto;
}

.subscriptions-card {
    width: 280px;
    height: 400px;
    background-color: #151515;
    margin-top: 20px;
    border-radius: 5px;
}

.subscriptions-card-content {
    padding-left: 20px;
    padding-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/suscriptions/Suscriptions.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".suscriptions-body {\n    width: 100%;\n    min-height: 1000px;\n    background-color: #0A0B0D;\n}\n\n.suscriptions-container {\n    width: 95%;\n    margin: 0 auto;\n}\n\n.subscriptions-card {\n    width: 280px;\n    height: 400px;\n    background-color: #151515;\n    margin-top: 20px;\n    border-radius: 5px;\n}\n\n.subscriptions-card-content {\n    padding-left: 20px;\n    padding-right: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
