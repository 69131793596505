// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.channel-clips-body {
    width: 100%;
    height: 100%;
    margin-top: 20px;
}

.channel-clips-container {
    width: 95%;
    min-height: 500px;
    padding: 0% 0% 0% 4%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
}

@media screen and (max-width: 758px) {
    .channel-clips-container {
        width: 95%;
        min-height: 500px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/channel/clips/Clips.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,oBAAoB;IACpB,aAAa;IACb,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI;QACI,UAAU;QACV,iBAAiB;QACjB,cAAc;QACd,aAAa;QACb,eAAe;QACf,uBAAuB;IAC3B;AACJ","sourcesContent":[".channel-clips-body {\n    width: 100%;\n    height: 100%;\n    margin-top: 20px;\n}\n\n.channel-clips-container {\n    width: 95%;\n    min-height: 500px;\n    padding: 0% 0% 0% 4%;\n    display: flex;\n    flex-wrap: wrap;\n    /* justify-content: center; */\n}\n\n@media screen and (max-width: 758px) {\n    .channel-clips-container {\n        width: 95%;\n        min-height: 500px;\n        margin: 0 auto;\n        display: flex;\n        flex-wrap: wrap;\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
