// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resetpassword-body {
    width: 100%;
    min-height: 1000px;
}

.resetpassword-container {
    width: 95%;
    margin: 0 auto;
}

.resetpassword-card-container {
    background-color: #0404048f;
    margin-top: 50px;
    color: #ededed;
    width: 40%;
    height: 400px;
    padding: 40px;
    border-radius: 5px;
}

.resetpassword-input {
    background-color: #151515;
    margin-top: 10px;
    padding: 10px;
}

.resetpassword-input h1{
    font-size: 14px;
}


.resetpassword-button {
    margin-top: 50px;
    position: relative;
    width: 140px;
    padding: 5px;
    background-color: transparent;
    border: 1px solid #f36196;
    color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
}


.resetpassword-button:hover {
    background-color: #762543;
  }


  @media screen and (max-width: 768px) {
    .resetpassword-body {
        min-height: 1700px;
    }

    .resetpassword-card-container {
        width: 90%;
    }

  }`, "",{"version":3,"sources":["webpack://./src/components/auth/ResetPassword.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,cAAc;IACd,UAAU;IACV,aAAa;IACb,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,6BAA6B;IAC7B,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gGAAgG;IAChG,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;;AAGA;IACI,yBAAyB;EAC3B;;;EAGA;IACE;QACI,kBAAkB;IACtB;;IAEA;QACI,UAAU;IACd;;EAEF","sourcesContent":[".resetpassword-body {\n    width: 100%;\n    min-height: 1000px;\n}\n\n.resetpassword-container {\n    width: 95%;\n    margin: 0 auto;\n}\n\n.resetpassword-card-container {\n    background-color: #0404048f;\n    margin-top: 50px;\n    color: #ededed;\n    width: 40%;\n    height: 400px;\n    padding: 40px;\n    border-radius: 5px;\n}\n\n.resetpassword-input {\n    background-color: #151515;\n    margin-top: 10px;\n    padding: 10px;\n}\n\n.resetpassword-input h1{\n    font-size: 14px;\n}\n\n\n.resetpassword-button {\n    margin-top: 50px;\n    position: relative;\n    width: 140px;\n    padding: 5px;\n    background-color: transparent;\n    border: 1px solid #f36196;\n    color: #ededed;\n    border-radius: 5px;\n    cursor: pointer;\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;\n    font-size: 12px;\n    font-weight: 600;\n    margin-right: 5px;\n}\n\n\n.resetpassword-button:hover {\n    background-color: #762543;\n  }\n\n\n  @media screen and (max-width: 768px) {\n    .resetpassword-body {\n        min-height: 1700px;\n    }\n\n    .resetpassword-card-container {\n        width: 90%;\n    }\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
