// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdowndays-body {
    width: 150px;
    position: absolute;
    right: 0;
    top: 90px;
    background-color: #151515;
    border-radius: 5px;
}

.dropdowndays-body p {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
}

.dropdowndays-body p:hover {
    background-color: #303030;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/analytics/DropdownDays.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".dropdowndays-body {\n    width: 150px;\n    position: absolute;\n    right: 0;\n    top: 90px;\n    background-color: #151515;\n    border-radius: 5px;\n}\n\n.dropdowndays-body p {\n    text-align: center;\n    margin-top: 15px;\n    font-size: 14px;\n    padding: 10px;\n    cursor: pointer;\n}\n\n.dropdowndays-body p:hover {\n    background-color: #303030;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
