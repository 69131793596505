// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-content-body {
    width: 100%;
    min-height: 1000px;
    background-color:#0a0b0d;

}

.dashboard-content-container {
    width: 95%;
    margin: 0 auto;
    position: relative;
    top: 50px;
    color: #ededed;
}

.dashboard-content-type {
    width: 100%;
    min-height: 200px;
}

.dashboard-content-card-primary {
    background-color: #161616;
    width: 100%;
    height: 40px;
    display: flex;
    border: 1px solid #2b2b2b;

}


.dashboard-content-card {
    background-color: #161616;
    width: 100%;
    height: 100px;
    display: flex;
    border-bottom: 1px solid #2b2b2b;
    border-left: 1px solid #2b2b2b;
    border-right: 1px solid #2b2b2b;

}

.dashboard-content-card-checkbox {
    width: 3%;
    height: 100px;
    display: flex;
    align-items: center;
}

.dashboard-content-card-video {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
}

.dashboard-content-card-visibility {
    width: 10%;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/content/Content.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,wBAAwB;;AAE5B;;AAEA;IACI,UAAU;IACV,cAAc;IACd,kBAAkB;IAClB,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,yBAAyB;;AAE7B;;;AAGA;IACI,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,aAAa;IACb,gCAAgC;IAChC,8BAA8B;IAC9B,+BAA+B;;AAEnC;;AAEA;IACI,SAAS;IACT,aAAa;IACb,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".dashboard-content-body {\n    width: 100%;\n    min-height: 1000px;\n    background-color:#0a0b0d;\n\n}\n\n.dashboard-content-container {\n    width: 95%;\n    margin: 0 auto;\n    position: relative;\n    top: 50px;\n    color: #ededed;\n}\n\n.dashboard-content-type {\n    width: 100%;\n    min-height: 200px;\n}\n\n.dashboard-content-card-primary {\n    background-color: #161616;\n    width: 100%;\n    height: 40px;\n    display: flex;\n    border: 1px solid #2b2b2b;\n\n}\n\n\n.dashboard-content-card {\n    background-color: #161616;\n    width: 100%;\n    height: 100px;\n    display: flex;\n    border-bottom: 1px solid #2b2b2b;\n    border-left: 1px solid #2b2b2b;\n    border-right: 1px solid #2b2b2b;\n\n}\n\n.dashboard-content-card-checkbox {\n    width: 3%;\n    height: 100px;\n    display: flex;\n    align-items: center;\n}\n\n.dashboard-content-card-video {\n    width: 30%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n}\n\n.dashboard-content-card-visibility {\n    width: 10%;\n    height: 100%;\n    color: white;\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
